.active .menu {
  /* background-color: rgba(98, 100, 167, .7); */
  color: #fff;
  background: linear-gradient(180deg, rgba(60, 62, 93, .6), rgba(60, 62, 93, 0) 33%), linear-gradient(225deg, rgba(60, 62, 93, .6) 33%, rgba(60, 62, 93, 0) 70%), linear-gradient(270deg, rgba(60, 62, 93, .6) 70%, rgba(60, 62, 93, 0) 94%), linear-gradient(315deg, rgba(60, 62, 93, .6) 33%, rgba(60, 62, 93, 0) 70%), linear-gradient(360deg, rgba(60, 62, 93, .6), rgba(60, 62, 93, 0) 33%), linear-gradient(90deg, rgba(98, 100, 167, .75) 6%, rgba(98, 100, 167, 0) 70%);
  border-left: 3px solid #fff;
}

.active .menu:hover {
  background: #4d4e7e;
}

.menu:hover {
  background: rgba(65, 66, 114, .7);
}

.activehover .menu:hover {
  background: rgba(65, 66, 114, .7);
  color: #3d8782 !important;
  border-radius: 4px;
}

.activeMenu {
  color: #3d8782
}

.activehover .help:hover * .typoTopic {
  /* background: #d3f4f1 !important; */
  color: #3d8782 !important;
  /* border-radius: 4px; */
}